import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { SettingsContext } from "../context/Settings/SettingsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";

import packageJson from '../../package.json';
import HexToRgb from "../components/HexToRgb";

import {
  makeStyles,
  Badge,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  useTheme,
  Typography,
  Box
} from "@material-ui/core";

import {
  VpnKey,
  Event,
  LocalOffer,
  PieChart,
  AccountTreeOutlined,
  ContactPhoneOutlined,
  DashboardOutlined,
  LibraryAddOutlined,
  PeopleAltOutlined,
  QuestionAnswerOutlined,
  SettingsOutlined,
  SyncAlt,
  WhatsApp,
  Send,
  Ballot,
  PlaylistAddCheck,
  RecentActors,
  SettingsSystemDaydream,
  Description
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 100px)",
    height: "calc(100vh - 100px)",
    alignItems: "center",
    fontSize: "0.8rem",
    marginTop: "auto"
  },
  boxMenu: {
    minWidth: "85px",
    width: "85px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "5px !important",
    margin: 5,
    marginLeft: 10,
    padding: 5,
    cursor: "pointer",
    "&:hover": {
      opacity: "1.8",
      border: "1px solid " + theme.palette.primary.contrastText
    },
    opacity: "80%"
  },
  subMenu: {
    borderRadius: 10,
    color: theme.palette.primary.contrastText,
    background: `linear-gradient(to right, 
    ${theme.palette.primary.main},

  rgba(
    ${HexToRgb(theme.palette.primary.main, true).r}, 
    ${HexToRgb(theme.palette.primary.main, true).g},
    ${HexToRgb(theme.palette.primary.main, true).b}, 
  0.9)
   )`,
    marginLeft: '95px',
  },
  subMenuList: {
    width: '280px',
    right: '0px',

  },
  subMenutitle: {
    paddingLeft: 20,
    color: theme.palette.primary.contrastText,
  },
  listItem: {
    marginBottom: 5,
    padding: 10,
    borderRadius: 5,
    "&:hover": {
      borderBottom: "1px solid " + theme.palette.primary.contrastText,
    },
  },
  listItemIcon: {
    borderRadius: 5,
    padding: 5,
    minWidth: "35px",
    width: "35px",
    border: "1px solid " + theme.palette.primary.contrastText,
    color: theme.palette.primary.contrastText,
  },
  listItemText: {
    marginLeft: 15,

  },
  iconMenu: {
    cursor: "pointer",
    border: "1px solid " + theme.palette.primary.contrastText,
    borderRadius: 5,
    padding: 5,
    minWidth: "35px",
    width: "35px",
    color: theme.palette.primary.contrastText,
    marginRight: 1,

  },
  iconMenuActive: {
    color: theme.palette.primary.main,
    background: theme.palette.primary.contrastText,
    "&:hover boxMenu": {
      opacity: "1.8",
      border: "1px solid " + theme.palette.primary.contrastText
    },
  },
  textMenu: {
    cursor: "pointer",
    fontSize: "0.8rem",
    color: theme.palette.primary.contrastText,
    marginTop: 5,
    // "& iconMenu:hover": {
    //   backgroundColor: 'rgb(7, 177, 77, 0.42)'
    // },
  }

}));


function ListItemLink(props) {
  const classes = useStyles();
  const { icon, primary, to, onClick } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink} onClick={onClick} className={classes.listItem}  >
        {icon ? <ListItemIcon className={classes.listItemIcon} >{icon}</ListItemIcon> : null}
        <ListItemText className={classes.listItemText} primary={primary} />
      </ListItem>
    </li>
  );
}


const IndexMenu = ({ primary, to, icon, selected, setSelected, onClick }) => {
  const classes = useStyles();

  return (
    <Box
      active={selected === primary}
      onClick={onClick}
      className={classes.boxMenu}
    >
      {icon ? <ListItemIcon className={`${classes.iconMenu} ${selected === primary ? classes.iconMenuActive : ""}`}>{icon}</ListItemIcon> : null}
      <Typography variant="span" color="primary" className={classes.textMenu} >{primary}</Typography>
    </Box>
  );
};


const MainListItems = ({ drawerClose, drawerOpen }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const { settings } = useContext(SettingsContext);
  const [modulesCampaigns, setmodulesCampaigns] = useState("disabled");
  const [modulesFinancial, setModulesFinancial] = useState();
  const [modulesOnlyCampaigns, setModulesOnlyCampaigns] = useState();

  const [selected, setSelected] = useState("Dashboard");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickSubMenu = (event, target) => {
    setAnchorEl(event.currentTarget);
    setSelected(target)
  };

  const handleClickMenu = (target) => {
    setAnchorEl(null);
    setSelected(target);

    if (drawerOpen === true) {
      drawerClose(false)
    }
  };


  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    const loadsettings = async () => {
      const modulesCampaigns = await settings.find(t => t.key === "modulesCampaigns");
      setmodulesCampaigns(modulesCampaigns?.value);

      const modulesFinancial = await settings.find(t => t.key === "modulesFinancial");
      setModulesFinancial(modulesFinancial?.value);

      const modulesOnlyCampaigns = await settings.find(t => t.key === "modulesOnlyCampaigns");
      setModulesOnlyCampaigns(modulesOnlyCampaigns?.value);

    }
    loadsettings();
  }, [settings]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {


      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);


  return (
    <>
      <div className={classes.flexContainer}>
        <Divider />
        {modulesOnlyCampaigns === "enabled" &&
          <>
            {/* Menu Atendimento */}
            <Can role={user.profile} perform="drawer-items:tickets" yes={() => (
              <>
                <IndexMenu primary={i18n.t("mainDrawer.listItems.campaigns")} icon={<Send />} selected={selected} setSelected={setSelected} onClick={(e) => handleClickSubMenu(e, i18n.t("mainDrawer.listItems.campaigns"))} />
                <Menu classes={{ paper: classes.subMenu, list: classes.subMenuList }} id={i18n.t("mainDrawer.listItems.campaigns")} anchorEl={anchorEl} open={selected === i18n.t("mainDrawer.listItems.campaigns") ? open : ""} onClose={handleClose}>
                  <Typography variant="h6" color="primary" className={classes.subMenutitle}>{i18n.t("mainDrawer.listItems.campaigns")}</Typography>
                  {modulesCampaigns === "enabled" &&
                    <ListItemLink to="/Campaigns" primary={i18n.t("mainDrawer.listItems.campaigns")} icon={<Send />} onClick={(e) => handleClickMenu(i18n.t("mainDrawer.listItems.campaigns"))} />
                  }
                  <Divider />
                  <ListItemLink to="/contacts" primary={i18n.t("mainDrawer.listItems.contacts")} icon={<ContactPhoneOutlined />} onClick={(e) => handleClickMenu(i18n.t("mainDrawer.listItems.campaigns"))} />
                  <ListItemLink to="/tagskanban" primary={i18n.t("mainDrawer.listItems.tags")} icon={<LocalOffer />} onClick={(e) => handleClickMenu(i18n.t("mainDrawer.listItems.campaigns"))} />
                </Menu>
              </>
            )}
            />
          </>
        }
        {modulesOnlyCampaigns !== "enabled" &&
          <>

            {/* Menu Atendimento */}
            <Can role={user.profile} perform="drawer-items:tickets" yes={() => (
              <>
                <IndexMenu primary={i18n.t("mainDrawer.listItems.service")} icon={<WhatsApp />} selected={selected} setSelected={setSelected} onClick={(e) => handleClickSubMenu(e, i18n.t("mainDrawer.listItems.service"))} />
                <Menu classes={{ paper: classes.subMenu, list: classes.subMenuList }} id={i18n.t("mainDrawer.listItems.service")} anchorEl={anchorEl} open={selected === i18n.t("mainDrawer.listItems.service") ? open : ""} onClose={handleClose}>
                  <Typography variant="h6" color="primary" className={classes.subMenutitle}>{i18n.t("mainDrawer.listItems.service")}</Typography>
                  <Divider />
                  <ListItemLink to="/tickets" primary={i18n.t("mainDrawer.listItems.tickets")} icon={<WhatsApp />} onClick={(e) => handleClickMenu(i18n.t("mainDrawer.listItems.service"))} />
                  <ListItemLink to="/contacts" primary={i18n.t("mainDrawer.listItems.contacts")} icon={<ContactPhoneOutlined />} onClick={(e) => handleClickMenu(i18n.t("mainDrawer.listItems.service"))} />
                  <ListItemLink to="/quickAnswers" primary={i18n.t("mainDrawer.listItems.quickAnswers")} icon={<QuestionAnswerOutlined />} onClick={(e) => handleClickMenu(i18n.t("mainDrawer.listItems.service"))} />
                  <ListItemLink to="/schedules" primary={i18n.t("mainDrawer.listItems.schedules")} icon={<Event />} onClick={(e) => handleClickMenu(i18n.t("mainDrawer.listItems.service"))} />
                  {modulesCampaigns === "enabled" &&
                    <ListItemLink to="/Campaigns" primary={i18n.t("mainDrawer.listItems.campaigns")} icon={<Send />} onClick={(e) => handleClickMenu(i18n.t("mainDrawer.listItems.service"))} />
                  }
                  <ListItemLink to="/tagskanban" primary={i18n.t("mainDrawer.listItems.tags")} icon={<LocalOffer />} onClick={(e) => handleClickMenu(i18n.t("mainDrawer.listItems.service"))} />
                </Menu>
              </>
            )}
            />
            {/* //Menu Atendimento */}
          </>
        }
        {/* Menu ADMIN */}
        <Can role={user.profile} perform="drawer-items:management" yes={() => (
          <>
            <IndexMenu selected={selected} setSelected={setSelected} onClick={(e) => handleClickSubMenu(e, "Admin")} primary="Admin" icon={<DashboardOutlined />} />
            <Menu classes={{ paper: classes.subMenu, list: classes.subMenuList }} id="Admin" anchorEl={anchorEl} open={selected === "Admin" ? open : ""} onClose={handleClose}>
              <Typography variant="h6" color="primary" className={classes.subMenutitle}>Admin</Typography>
              <Divider />
              {modulesOnlyCampaigns !== "enabled" &&
                <>
                  <ListItemLink to="/Dashboard" primary="Dashboard" icon={<DashboardOutlined />} onClick={(e) => handleClickMenu("Admin")} />
                  <ListItemLink to="/Reports" primary={i18n.t("mainDrawer.listItems.reports")} icon={<PieChart />} onClick={(e) => handleClickMenu("Admin")} />
                </>
              }
              <Can role={user.profile} perform="drawer-items:supervisor" yes={() => (
                <>
                  <ListItemLink to="/users" primary={i18n.t("mainDrawer.listItems.users")} icon={<PeopleAltOutlined />} onClick={(e) => handleClickMenu("Admin")} />
                  <ListItemLink to="/connections" primary={i18n.t("mainDrawer.listItems.connections")}
                    icon={
                      <Badge overlap="rectangular" badgeContent={connectionWarning ? "!" : 0} color="error" >
                        <SyncAlt />
                      </Badge>
                    }
                    onClick={(e) => handleClickMenu(i18n.t("Admin"))} />
                  {modulesOnlyCampaigns !== "enabled" &&
                    <ListItemLink to="/queues" primary={i18n.t("mainDrawer.listItems.queues")} icon={<AccountTreeOutlined />} onClick={(e) => handleClickMenu(i18n.t("Admin"))} />
                  }
                  <ListItemLink to="/settings" primary={i18n.t("mainDrawer.listItems.settings")} icon={<SettingsOutlined />} onClick={(e) => handleClickMenu(i18n.t("Admin"))} />
                  <Divider />
                  <ListItemLink to="/tokens" primary={i18n.t("mainDrawer.listItems.tokens")} icon={<VpnKey />} onClick={(e) => handleClickMenu(i18n.t("Admin"))} />
                  <ListItemLink to="/docs" primary={i18n.t("mainDrawer.listItems.docs")} icon={<Description classes={{ paper: classes.subMenu, list: classes.subMenuList }} Book />} onClick={(e) => handleClickMenu(i18n.t("Admin"))} />
                </>
              )}
              />
            </Menu>
          </>
        )}
        />
        {/* //Menu ADMIN */}

        {modulesOnlyCampaigns !== "enabled" &&
          <>
            {/* Menu FINANCEIRO */}
            {modulesFinancial === "enabled" &&
              <Can role={user.profile} perform="drawer-items:finance" yes={() => (
                <>
                  <IndexMenu selected={selected} setSelected={setSelected} onClick={(e) => handleClickSubMenu(e, "Financeiro")} primary="Financeiro" icon={<RecentActors />} />
                  <Menu classes={{ paper: classes.subMenu, list: classes.subMenuList }} id="Financeiro" anchorEl={anchorEl} open={selected === "Financeiro" ? open : ""} onClose={handleClose}>
                    <Typography variant="h6" color="primary" className={classes.subMenutitle}>{i18n.t("mainDrawer.listItems.financial.title")}</Typography>
                    <Divider />
                    <ListItemLink to="/plans" primary={i18n.t("mainDrawer.listItems.financial.plans")} icon={<PlaylistAddCheck />} onClick={(e) => handleClickMenu(i18n.t("mainDrawer.listItems.financial.title"))} />
                    <ListItemLink to="/subscriptions" primary="Assinaturas" icon={<Ballot />} onClick={(e) => handleClickMenu(i18n.t("mainDrawer.listItems.financial.title"))} />
                  </Menu>
                </>
              )}
              />
            }
            {/* //Menu FINANCEIRO */}
          </>
        }

        <div style={{ alignItems: "center", display: "flex", fontSize: "0.8rem", flexDirection: "column", marginTop: "auto" }}>

          <Can role={user.profile} perform="drawer-items:super" yes={() => (
            <>
              <Divider />
              <IndexMenu selected={selected} setSelected={setSelected} onClick={(e) => handleClickSubMenu(e, "Super")} primary="Super" icon={<SettingsSystemDaydream />} />
              <Menu classes={{ paper: classes.subMenu, list: classes.subMenuList }} id="Super" anchorEl={anchorEl} open={selected === "Super" ? open : ""} onClose={handleClose}>
                <Typography variant="h6" color="primary" className={classes.subMenutitle}>Super</Typography>
                <Divider />
                <ListItemLink to="/super" primary={i18n.t("mainDrawer.listItems.super.tennants")} icon={<LibraryAddOutlined />} onClick={(e) => handleClickMenu("Super")} />
                <ListItemLink to="/msServers" primary="Servidores" icon={<Ballot />} onClick={(e) => handleClickMenu("Super")} />
              </Menu>
            </>
          )}
          />
          <span style={{ color: theme.palette.primary.contrastText }} >V: {packageJson.systemVars.version}</span>
        </div>
      </div>
    </>
  );
};

export default MainListItems;
