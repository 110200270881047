const messages = {
  pt: {
    translations: {
      signup: {
        title: "Cadastre-se",
        toasts: {
          success: "Usuário criado com sucesso! Faça seu login!!!.",
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
      },
      login: {
        title: "Login",
        textAccessCode: "Enviamos um código de acesso para seu e-mail, por favor verifique seu e-mail. Caso não encontre, verifique na pasta spam.",
        form: {
          email: "Email",
          password: "Senha",
          accessCode: "Código de Acesso",
        },
        buttons: {
          submit: "Entrar",
          register: "Não tem um conta? Cadastre-se!",
        },
      },
      auth: {
        toasts: {
          success: "Login efetuado com sucesso!",
        },
      },

      reports: {
        filters: {
          today: "Hoje",
          days: "Dias",
          month: "Mês",
          filterBy: "Filtrar Por",
            modal: {
              title: "Mais opções de filtro",
              dateInitial: "Data Inicial",
              dateFinal: "Data Final",
              button: {
                title: "Filtrar"
              }
          }
        },
        charts: {
          yearly: {
            title: "Atendimentos Finalizados em"
          }
        },
        usersRankinglist: {
          title: "Ranking de Atendimentos",
          name: "Nome"
        },
        status: {
          inAttendance: "Em Atendimento",
          waiting: "Aguardando",
          closed: "Finalizados",
          unassigned: "Não atribuído"
        },      
      },

      dashboard: {
        charts: {
          perDay: {
            title: "Tickets hoje: ",
          },
        },
        messages: {
          inAttendance: {
            title: "Em Atendimento"
          },
          waiting: {
            title: "Aguardando"
          },
          closed: {
            title: "Finalizado"
          }
        }
      },
      connections: {
        title: "Conexões",
        toasts: {
          deleted: "Conexão com o WhatsApp excluída com sucesso!",
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
          disconnectTitle: "Desconectar",
          disconnectMessage:
            "Tem certeza? Você precisará ler o QR Code novamente.",
        },
        buttons: {
          add: "Adicionar WhatsApp",
          disconnect: "desconectar",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
        },
        toolTips: {
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code",
          },
          qrcode: {
            title: "Esperando leitura do QR Code",
            content:
              "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
          },
          connected: {
            title: "Conexão estabelecida!",
          },
          timeout: {
            title: "A conexão com o celular foi perdida",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
          },
        },
        table: {
          name: "Nome",
          number: "Número",
          status: "Status",
          lastUpdate: "Última atualização",
          default: "Padrão",
          campaigns: "Campanhas",
          actions: "Ações",
          session: "Sessão",
        },
      },
      whatsappModal: {
        title: {
          add: "Adicionar WhatsApp",
          edit: "Editar WhatsApp",
        },
        form: {
          name: "Nome",
          default: "Padrão",
          farewellMessage: "Mensagem de despedida",
          campaignsEnabled: "Campanhas",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "WhatsApp salvo com sucesso.",
      },
      qrCode: {
        message: "Leia o QrCode para iniciar a sessão",
      },
      contacts: {
        title: "Contatos",
        toasts: {
          deleted: "Contato excluído com sucesso!",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Deletar ",
          deleteAllTitle: "Deletar Todos",
          importTitle: "Importar contatos",
          deleteMessage: "Tem certeza que deseja deletar este contato? Todos os tickets relacionados serão perdidos.",
          deleteAllMessage: "Tem certeza que deseja deletar todos os contatos? Todos os tickets relacionados serão perdidos.",
          importMessage: "Deseja importar todos os contatos do telefone?",
        },
        buttons: {
          import: "Importar Contatos",
          add: "Adicionar Contato",
          export: "Exportar todos Contatos",
          delete: "Excluir Todos Contatos"
        },
        table: {
          name: "Nome",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Ações",
        },
      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar contato",
        },
        form: {
          mainInfo: "Dados do contato",
          extraInfo: "Informações adicionais",
          name: "Nome",
          number: "Número do Whatsapp",
          email: "Email",
          extraName: "Nome do campo",
          extraValue: "Valor",
        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Contato salvo com sucesso.",
      },
      quickAnswersModal: {
        title: {
          add: "Adicionar Resposta Rápida",
          edit: "Editar Resposta Rápida",
        },
        form: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Resposta Rápida salva com sucesso.",
        tip: "Digite @ para opções de variáveis."
      },
      queueModal: {
        title: {
          add: "Adicionar fila",
          edit: "Editar fila",
        },
        form: {
          name: "Nome",
          color: "Cor",
          order: "Ordem",
          greetingMessage: "Mensagem de saudação",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Usuário salvo com sucesso.",
      },
      tagModal: {
        title: {
          add: "Nova Tag",
          edit: "Editar Tag",
        },
        form: {
          name: "Nome",
          color: "Cor"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Tag salvo com sucesso.",
      },
      chat: {
        noTicketMessage: "Selecione um ticket para começar a conversar.",
      },
      uploads: {
        titles: {
          titleUploadMsgDragDrop: "ARRASTE E SOLTE ARQUIVOS NO CAMPO ABAIXO",
          titleFileList: "Lista de arquivo(s)"
        },
      },
      ticketsManager: {
        buttons: {
          newTicket: "Novo Contato/WhatsApp",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Setores",
      },
      tickets: {
        toasts: {
          deleted: "O ticket que você estava foi deletado.",
        },
        notification: {
          message: "Mensagem de",
        },
        tabs: {
          open: { title: "Inbox" },
          closed: { title: "Resolvidos" },
          search: { title: "Busca" },
        },
        search: {
          placeholder: "Buscar tickets e mensagens",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabel: "Digite para buscar usuários",
        fieldQueueLabel: "Transferir para fila",
        fieldConnectionLabel: "Transferir para conexão",
        fieldQueuePlaceholder: "Selecione uma fila",
        fieldConnectionPlaceholder: "Selecione uma conexão",
        noOptions: "Nenhum usuário encontrado com esse nome",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        searchHeaderTip: "Pesquisar",
        closedHeaderTip: "Lista de mensagens finalizados",
        pendingHeader: "Aguardando",
        pendingHeaderTip: "Lista de mensagens aguardando atendimento",
        assignedHeader: "Atendendo",
        assignedHeaderTip: "Lista de mensagens em atendimento",
        noTicketsTitle: "Nada aqui, ou sua pesaquisa não retornou registros",
        noTicketsMessage:
          "Nenhum ticket encontrado com esse status ou termo pesquisado",
        connectionTitle: "Conexão que está sendo utilizada atualmente.",
        userTitle: "Nome do usuário atribuído a este ticket.",
        searchResult: "Resultado da pesquisa",
        buttons: {
          assignMe: "Atribuir-me",
          accept: "Atender", 
          acceptBeforeBot: "Atender",
          spy: "Espiar",
          start: "Iniciar",
          cancel: "Cancelar"
        },
        acceptModal: {
          title: "Aceitar atendimento",
          queue: "Escolha a fila"
        }
      },
      newTicketModal: {
        title: "Criar Ticket",
        fieldLabel: "Digite para pesquisar o contato",
        add: "Adicionar",
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          reports: "Relatórios",
          connections: "Conexões",
          service: "Atendimento",
          tickets: "Tickets",
          contacts: "Contatos",
          quickAnswers: "Respostas Rápidas",
          queues: "Setores & Chatbot",
          schedules: "Agendamentos",
          campaigns: "Campanhas",
          tags: "Tags",
          api: "API",
          administration: "ADMINISTRAÇÃO",
          users: "Usuários",
          docs: "Documentação",
          tokens: "Tokens",
          settings: "Configurações",
          version: "Versão",
          financial: {
            title: "Financeiro",
            plans: "Planos",
          },
          Streaming: {
            title: "STREAMING",
            servers: "Servidores",
          },
          super: {
            title: "Multi Clientes",
            tennants: "Gerenciar Clientes",
          },
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Sair",
          },
        },
      },
      notifications: {
        noTickets: "Nenhuma notificação.",
      },
      schedules: {
        title: "Agendamentos",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir este Agendamento?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          contact: "Contato",
          body: "Mensagem",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio",
          status: "Status",
          actions: "Ações",
        },
        buttons: {
          add: "Novo Agendamento",
        },
        toasts: {
          deleted: "Agendamento excluído com sucesso.",
        }
      },
      scheduleModal: {
        title: {
          add: "Novo Agendamento",
          edit: "Editar Agendamento",
        },
        form: {
          body: "Mensagem",
          contact: "Contato",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Agendamento salvo com sucesso.",
      },

      campaigns: {
        title: "Campanhas",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Campanhas?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          name: "Nome da Campanha",
          contact: "Contato",
          msg: "Mensagem",
          sendAt: "Agendado para:",
          sentAt: "Data de Envio",
          status: "Status",
          analytics: "Estatísticas",
          user: "Usuário",
          delay: "Velocidade envio (em segundos)",
          actions: "Ações",
        },
        buttons: {
          add: "Nova Campanha",
          report: "Relatório da campanha",
          view: "Visualizar campanha",
          delete: "Excluir campanha"
        },
        toasts: {
          deleted: "Campanha excluída com sucesso.",
        },
        status: {
          start: "Start",
          pending: {
            name: "Pendente",
            buttonTitle: "Campanha pendente. Clique para pausar"
          },
          sent: { name: "Enviado" },
          paused: {
            name: "Pausado",
            buttonTitle: "Campanha pausada. Clique para iniciar"
          },
          finished: { name: "Finalizado" },
          changeStatusSuccess: "Status atualizado para",
          changeDelay: "Velocidade de envio atualizado"
        },
        color: {
          pending: "#fff4e5",
          sent: "#edf7ed"
        }
      },
      campaignsModal: {
        title: {
          add: "Nova Campanha",
          edit: "Editar Campanha",
          noConnected: "Nenhum whatsApp conectado ou Habilitado. Vá até o menu CONEXÕES ou fale com o suporte"
        },
        form: {
          name: "Nome da Campanha",
          msg: "Mensagem",
          contact: "Contato",
          sendAt: "Agendado para:",
          connection: "Conexão",
          attachFiles: "Anexar arquivos",
          delay: {
            name: "Velocidade",
            seconds: "segundos",
            minute: "minuto",
            recommendedMinimum: "mínimo recomendado",
          },
          openTicket: {
            name: "Selecione uma opção",
            options: {
              false: "Não abrir ticket",
              true: "Abrir/Manter ticket aberto",
              trueClose: "Abrir e fechar Ticket após envio" 
            }
          },
          caption: {
            name: "Enviar imagem/vídeo junto com o texto",
            note: "Se ativado, o texto vai junto com imagem/vídeo. Exceto se yiver mais de 1 arquivos."
          }
        },
        buttons: {
          okSave: "Salvar",
          cancel: "Cancelar",
          close: "Fechar"
        },
        success: "Campanha salva com sucesso.",
        infoStatusWA: "*Estatísticas dependem das configurações de status do WhatsApp.",
      },
      campaignsRepostsSaveListModal: {
        info: "Selecione ou digite, no campo acima, o nome da etiqueta/tag para salvar os ##. Caso a etiqueta/tag não exista, será criado uma nova.",
        tagName: "Nome da Etiqueta/tag",
        success: "A lista foi salva com sucesso na Etiqueta/tag",
      },

      campaignsRepostsModal: {
        title: "Relatório de Campanhas",
        sent: "Enviados",
        pending: "Pendentes",
        error: "Erro",
        close: "Fechar",
        name: "Nome",
        number: "Número",
        dateHoraSend: "Data/Hora envio",
        dateHoraChecked: "Data/Hora verificado",
        unreceived: "Não recebido",
        unread: "Não lido",
        read: "Lido",
        
      },

      queues: {
        title: "Filas",
        table: {
          name: "Nome",
          color: "Cor",
          order: "Ordem",
          greeting: "Mensagem de saudação",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar fila",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! Os tickets dessa fila continuarão existindo, mas não terão mais nenhuma fila atribuída.",
        },
      },
      queueSelect: {
        inputLabel: "Filas",
      },
      quickAnswers: {
        title: "Respostas Rápidas",
        table: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Resposta Rápida",
        },
        toasts: {
          deleted: "Resposta Rápida excluída com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Resposta Rápida: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      plan: {
        title: "Planos",
        name: "Plano"
      },
      msServer: {
        title: "Servidores",
        name: "Servidor",
        valueCredit: "Valor do crédito unitário",
        typeServer: "Tipo",
        domain: "Domínio",
      },
      subscription: {
        title: "Assinaturas",
        name: "Assinatura",
        userData: "Dados Usuário",
        sendAlert: "Enviar Alerta de Vencimento e dados da assinatura",
        sendAlertModal: {
          title: "Enviar Alerta de Vencimento e dados da assinatura",
          success: "Alerta de Vencimento e dados da assinatura enviado com sucesso!",
          openTicket: {
            name: "Selecione uma opção",
            options: {
              false: "Não abrir ticket",
              true: "Abrir/Manter ticket aberto",
              trueClose: "Abrir e fechar Ticket após envio" 
            }
          },

        }
      },
      contactLink: {
        title: "Contatos Vinculados",
        name: "Vínculo",
      },
      launcherBox: {
        cod: "Código da Launcher",
      },
      
      users: {
        title: "Usuários",
        table: {
          name: "Nome",
          email: "Email",
          profile: {
            title: "Perfil",
            role: {
              admin: "Administrador",
              dashboard: "Relatórios",
              finance: "Financeiro",
              super: "Gerente do Sistema",
              supervisor: "Supervisor",
              user: "Usuário",
            },
          },
          whatsapp: "Conexão Padrão",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar usuário",
        },
        toasts: {
          deleted: "Usuário excluído com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados do usuário serão perdidos. Os tickets abertos deste usuário serão movidos para a fila.",
        },
      },
      tags: {
        title: "Tags",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Tag?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        confirmModalDeleteContact: {
          deleteTitle:
            "Você tem certeza que quer desvincular esse Contato da Tag?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          name: "Nome",
          color: "Cor",
          tickets: "Registros Tagdos",
          actions: "Ações",
        },
        buttons: {
          add: "Nova Tag",
        },
        forms: {
          inputLabel: "Tags"
        },
        toasts: {
          deleted: "Tag excluído com sucesso.",
          contactDeleted: "Contato desvinculado com sucesso.",
        },
        info: {
          minimumCharacters: "Digite no mínimo 3 caracteres.",
        }
      },
      settings: {
        success: "Configurações salvas com sucesso.",
        title: "Configurações",
        support: "Suporte",
        activate: "Ativar",
        settings: {
          title: "Gerenciar Configurações do Sistema",
          userCreation: {
            name: "Criação de usuário",
            note: "Se havilitado, usuários serão capazes de criar novos cadastros pela tela de login.",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          timeCreateNewTicket: {
            name: "MENSAGEM DE BOAS-VINDAS APÓS",
            note: "Selecione o tempo que será necessário para abrir um novo ticket, caso o cliente entre em contatos novamente",
            options: {
              "10": "10 Segundos",
              "30": "30 Segundos",
              "60": "1 minuto",
              "300": "5 minutos",
              "1800": "30 minutos",
              "3600": "1 hora",
              "7200": "2 horas",
              "21600": "6 horas",
              "43200": "12 horas",
              "86400": "24 horas",
              "172800": "48 horas",
            },
          },
          hoursCloseTicketAuto: {
            name: "FECHAR TICKET APÓS (HORAS)",
            note: "Selecione o tempo, em horas, em que os tickets serão fechados automaticamente",
            options: {
              "0": "NUNCA",
              "1": "1 hora",
              "2": "2 horas",
              "4": "4 horas",
              "6": "6 horas",
              "12": "12 horas",
              "24": "24 horas",
              "36": "36 horas",
              "48": "48 horas",
              "60": "60 horas",
              "72": "72 horas"
            },
          },
          call: {
            name: "ACEITAR CHAMADAS",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          queuedTicketsOnly: {
            name: "SEM FILA: SOMENTE PARA ADMIN",
            note: "Se habilitado, apenas administrador poderá ver os tickets sem fila.",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          CheckMsgIsGroup: {
            name: "IGNORAR MENSAGENS DE GRUPOS",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          ticketWaitingNoShowMessagem: {
            name: "TICKET PENDENTE: NÃO MOSTRAR MENSAGEM",
            note: "Ativado: se o ticket estiver pendente, não mostrar mensagem.",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          sendMessageToSelectQueue: {
            name: "MENSAGEM PARA SELECIONAR FILA",
            note: "Se ativo, os cliente receberão uma mensagem para selecionar uma fila do menu.",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          userSpyTicket: {
            name: "Usuário pode espiar ticket",
            note: "Se ativado, os usuários poderão visualizar os ticket em AGUARDANDO.",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          showSignMessage: {
            name: "Usuário pode desativar assinatura",
            note: "Se ativado, os usuários poderão desativar as assinaturas ao enviar mensagens.",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          }, 
          painelTVS: { 
            name: "Gerenciar clientes do Painel TVS: Coloque os dados do painel conforme exemplo: usuário[@]senha",
            note: "COLOQUE OS SEGUINTES DADOS: usuário[@]senha.",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          launcherBox: { 
            name: "Gerenciar clientes do Painel da LauncherBox: Coloque os dados do painel conforme exemplo: usuário[@]senha",
            note: "COLOQUE OS SEGUINTES DADOS: usuário[@]senha.",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },

        },
      },
      
      messagesList: {
        header: {
          assignedTo: "Atribuído à:",
          buttons: {
            return: "Retornar",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceitar",
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Digite uma mensagem ou tecle ''/'' para utilizar as respostas rápidas cadastrada",
        placeholderClosed:
          "Reabra ou aceite esse ticket para enviar uma mensagem.",
        signMessage: "Assinar",
      },
      contactDrawer: {
        header: "Dados do contato",
        extraInfo: "Outras informações",
      },
      ticketOptionsMenu: {
        delete: "Deletar",
        transfer: "Transferir",
        confirmationModal: {
          title: "Deletar o ticket do contato",
          message:
            "Atenção! Todas as mensagens relacionadas ao ticket serão perdidas.",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Deletar",
        reply: "Responder",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
      },
      variables: {
        list: {
          // Contact vars
          contact_firstName: "Contato: Primeiro nome",
          contact_fullName: "Contato: Nome completo",
          contact_email: "Contato: E-mail",
          contact_phone: "Contato: Telefone",
          contact_avatar: "Contato: Avatar",
          contact_dateCreate: "Contato: Data criado",
          contact_dateUpdate: "Contato: Data atualizado",

          // Ticket vars
          ticket_id: "Ticket: ID",
          ticket_status: "Ticket: Status",
          ticket_lastMessage: "Ticket: Última mensagem",
          ticket_dateCreation: "Ticket: Data criado",
          ticket_dateUpdate: "Ticket: Data atualizado",

          // User vars: use if exists
          user_name: "Usuário: Nome",
          user_email: "Usuário: email",

          // Misc
          break_message: "Quebrar mensagem",
          greeting: "Saudação",
          year: "Ano",
          month: "Mês",
          day: "Dia",
          hour: "Hora",
          minute: "Minuto",
          second: "Segundo",
          date_iso: "Data ISO",
          date_us: "Data US",
          date_br: "Data BR",
          protocol: "Protocolo",
        }
      },
      super: {
        title: "Área Multi Clientes",
        activate: "Ativar",
        settings: {
          title: "Gerenciar Configurações de Clientes",
          dueDate: {
            name: "Data de vencimento",
            note: "Data de vencimento",
          },
          billingUrl: {
            name: "Link para pagamento",
            note: "Link para pagamento",
          },
          billingPrice: {
            name: "Valor para pagamento",
            note: "Valor para pagamento",
          },
          modulesCampaigns: {
            name: "Módulo de Campanhas",
            note: "Módulo de Campanhas",
            options: {
              nothing: "Não instalado",
              install: "Instalar módulo",
              enabled: "Ativado",
              disabled: "Desativado",
            }
          },
          accessCode: {
            name: "Código de Acesso por E-mail",
            note: "Pedir Código de Acesso por E-mail",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            }
          },
          modulesStreaming: {
            name: "Servidores de Streaming",
            note: "Será aberto campos [SERVIDOR, USUÁRIO E SENHA, IDLAUNCHER]",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            }
          },
          modulesFinancial: {
            name: "Módulo Financeiro",
            note: "",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            }
          },
          modulesOnlyCampaigns: {
            name: "Somente Campanhas",
            note: "Ativa somente campanhas, sem sistema de atendimento",
            options: {
              nothing: "Não instalado",
              install: "Instalar módulo",
              enabled: "Ativado",
              disabled: "Desativado",
            }
          },
        },
      },
      validation: {
        required: "Obrigatório",
        too_short: "Muito curta",
        too_long: "Muito longa",
        notRequired: "Opcional",
      },

      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
        ERR_NO_PERMISSION_CREATE_CONNECTION_LIMIT: "Você alcançou o limite de conexões para a conta. Por favor, entre em contato com a Bits TI para adquirir licenças adicionais.",
        ERR_NO_DEF_WAPP_FOUND: "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
        ERR_WAPP_NOT_INITIALIZED: "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
        ERR_WAPP_CHECK_CONTACT: "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA: "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
        ERR_INVALID_CREDENTIALS: "Erro de autenticação. Por favor, tente novamente.",
        ERR_SENDING_WAPP_MSG: "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Já existe um atendimento aberto para este contato.",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
        ERR_USER_CREATION_DISABLED: "A criação do usuário foi desabilitada pelo administrador.",
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_NO_PERMISSION_INDEX: "Você não tem permissão para acessar este recurso (INDEX).",
        ERR_NO_PERMISSION_UPDATE_SETTING: "Você não tem permissão para atualizar essa configuração.",
        ERR_NO_PERMISSION_UPDATE_USER: "Você não tem permissão para atualizar usuários.",
        ERR_NO_PERMISSION_CREATE_USER: "Você não tem permissão para criar usuários.",
        ERR_NO_PERMISSION_CREATE_USER_LIMIT: "Você alcançou o limite de usuários para a conta. Por favor, entre em contato para adquirir licenças adicionais.",
        ERR_NO_PERMISSION_REMOVE_USER: "Você não tem permissão para remover usuários.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_TICKET_FOUND: "Nenhum atendimento encontrado com este ID.",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TICKET: "Erro ao criar atendimento no banco de dados.",
        ERR_FETCH_WAPP_MSG: "Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS: "Esta cor já está em uso, escolha outra.",
        ERR_WAPP_GREETING_REQUIRED: "A mensagem de saudação é obrigatório quando há mais de uma fila.",
        ERR_PLAN_SAVE: "Erro ao salvar o plano.",
        ERR_PLAN_INVALID_NAME: "Nome do plano obrigatório",
        ERR_PLAN_DUPLICATED: "Nome do plano duplicado",
        ERR_MSSERVER_SAVE: "Erro ao salvar o servidor.",
        ERR_MSSERVER_INVALID_NAME: "Nome do servidor obrigatório",
        ERR_MSSERVER_DUPLICATED: "Nome do servidor duplicado",
        ERR_GENERIC: "Foi encontrado algum erro!"
      },
      global: {
        welcome: "Seja bem-vindo!",
        name: "Nome",
        description: "Descrição",
        activedAt: "Ativado em",
        updatedAt: "atualizado em",
        expiresAt: "Vencimento",
        status: "Ativo",
        actions: "Ações",
        active: "Ativo",
        successSave: "Salvo com sucesso.",
        noOptions: "Nada encontrado",
        user: "Usuário",
        pass: "Senha",
        value: "Valor",
        price: "Preço",
        plan: "Plano",
        server: "Servidor",
        searchPlaceholder: "Pesquisar...",
        subscription: "Assinante",
        filter: "Filtro",
        toasts: {
          deleted: "excluído(a) com sucesso.",  
        },
        deleteTitle: "Você tem certeza que quer excluir este(a) ",
        deleteMessage: "Esta ação não pode ser revertida.",
        creditValidity:  "Val. Crédito",
        lastAccess: "Utl. Acesso",
        buttons: {
          new: "Novo",
          add: "Adicionar",
          edit: "Editar",
          delete: "Excluir",
          actualize: "Atualizar",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
          close: "Fechar",
          send: "Enviar"
          
        }
      }
    },
  },
};

export { messages };
